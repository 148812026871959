let startWindowScroll = 0;
const magnificPopupDefaultOptions = {
  fixedContentPos: true,
  fixedBgPos: true,
  overflowY: 'auto',
  callbacks: {
    beforeOpen: function() {
      startWindowScroll = $(window).scrollTop();
    },
    open: function(){
      if ( $('.mfp-content').height() < $(window).height() ){
        $('body').on('touchmove', function (e) {
          e.preventDefault();
        });
      }
    },
    close: function() {
      $(window).scrollTop(startWindowScroll);
      $('body').off('touchmove');
    }
  },
  modal: true,
  showCloseBtn: false,
  removalDelay: 300,
  mainClass: 'mfp-fade',
};

export default function magnificPopupOptions(options) {
  return Object.assign(magnificPopupDefaultOptions, options);
};