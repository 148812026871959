import { toBoolean } from '../lib/utils';

$(document).on('turbolinks:load', function(e) {
  // フォームのテスト
  function collapseFormTest($elm) {
    switch ($elm.prop('type')) {
    case 'checkbox':
      return $elm.prop('checked');
    case 'select-one':
      return 0 < parseInt($elm.val());
    case 'radio':
      return ($elm.prop('checked') && toBoolean($elm.val()));
    }
    return false
  }

  // 要素を表示
  function showElement($target) {
    $target.removeClass('hidden');
    $target.attr('aria-hidden', 'false');
  }

  // 要素を非表示
  function hideElement($target) {
    $target.addClass('hidden');
    $target.attr('aria-hidden', 'true');
  }

  // 要素をスライドして表示
  function collapseShowSlide($target) {
    $target.slideDown('fast', function() {
      showElement($target);
    });
  }

  // 要素をスライドして非表示
  function collapseHideSlide($target) {
    $target.slideUp('fast', function() {
      hideElement($target);
    });
  }

  // フォームの状態によってターゲットの要素を表示・非表示
  function updateCollapseBlocks(elm, slide = true) {
    const $elm = $(elm);
    const $target = $(`[data-id="${$elm.data('collapse-target')}"]`);

    let check = collapseFormTest($elm);
    if (check) {
      if ($target && $target.hasClass('hidden')) {
        if (slide) {
          collapseShowSlide($target)
        } else {
          showElement($target)
        }
      }
    } else {
      if ($target && !$target.hasClass('hidden')) {
        if (slide) {
          collapseHideSlide($target)
        } else {
          hideElement($target)
        }
      }
    }
  }

  if (!e.originalEvent.data.timing.visitStart) {
    $(document).on('initCollapse change', '[data-collapse-target]', function(e) {
      const $this = $(this);
      const isChange = (e.type == 'change');
      if ('radio' == $this.prop('type')) {
        // radioの時は同nameフォームの非表示処理も発火しないといけない
        $(`input[type="radio"][name="${$this.prop('name')}"][data-collapse-target]`).each(function(idx, elm) {
          updateCollapseBlocks(elm, isChange);
        });
        return;
      }
      updateCollapseBlocks(this, isChange);
    });
  }

  $('[data-collapse-target]').trigger('initCollapse');
});
