import { numberWithDelimiter } from '../lib/utils';
import magnificPopupOptions from './magnific_popup_options';
import Rails from '@rails/ujs';

$(document).on('turbolinks:load', function(e) {
  const updateCartContentsCountBadge = function(count) {
    const $cartContentsCount = $(`[data-id="cart-contents-count"]`);
    if (typeof(count) !== 'undefined') {
      $cartContentsCount.text(count);
    }
    if (0 < parseInt($cartContentsCount.text())) {
      $cartContentsCount.removeClass('hidden');
    } else {
      $cartContentsCount.addClass('hidden');
    }
  }


  if (!e.originalEvent.data.timing.visitStart) {
    // documentにイベントハンドラを設定するために最初のページロード時のみ実行する

    $(document).on('ajax:success', '[data-ajax-handler="addCartContent"]', function(e) {
      const [response, _status, _xhr] = e.detail;
      // ダイアログを開く
      $.magnificPopup.open(magnificPopupOptions({
        items: {
          src: response.partial,
          type: 'inline',
        }
      }));
      console.log(response);
      updateCartContentsCountBadge(response.total_count);
      return false;
    }).on('ajax:success', '[data-ajax-handler="removeCartContent"]', function(e) {
      // カートから削除
      const [response, _status, _xhr] = e.detail;
      const $target = $(e.target);
      $target.closest('.cart-content').fadeOut('normal', function() {
        $(this).html(response.partial).hide().fadeIn();
      });
      const $cart_group = $target.closest('.cart-group');
      $cart_group.find('[data-id="totalPrice"]').html(response.total_price);
      if (response.total_count == 0) {
        $cart_group.find('[data-id="buttonCheckout"]').addClass('disabled').attr('disabled', 'disabled').attr('tabindex', -1);
      }
      updateCartContentsCountBadge(response.total_count);
      return false;
    }).on('ajax:success', '[data-ajax-handler="updateCartContent"]', function(e) {
      // カート内容の変更完了
      const [response, status, _xhr] = e.detail;
      const $target = $(e.target);
      const $cart_group = $target.closest('.cart-group');
      $cart_group.find('[data-id="totalPrice"]').html(response.total_price);
      $target.closest('.cart-content').replaceWith(response.partial);
      updateCartContentsCountBadge(response.total_count);
      return false;
    }).on('change', 'form[data-ajax-handler="updateCartContent"] select[name="quantity"]', function(e) {
      // 個数変更
      const $form = $(this).closest('form[data-ajax-handler="updateCartContent"]');
      Rails.fire($form[0], 'submit');
      return false;
    });
  }

  updateCartContentsCountBadge();
});
