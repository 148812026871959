$(document).on('turbolinks:load', function() {
  const $orderConfirmForm = $('form[data-id="orderConfirmForm"]')
  const $submitBtn = $orderConfirmForm.find('input[type="submit"]')

  $orderConfirmForm.on('submit', (e) => {
    $submitBtn.prop("disabled", true)
    return true
  })

  $('form[data-auto-submit-on-load]').submit();
})
