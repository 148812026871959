import magnificPopupOptions from './magnific_popup_options';

$(document).on('turbolinks:load', function(e) {
  if (!e.originalEvent.data.timing.visitStart) {
    // documentにイベントハンドラを設定するために最初のページロード時のみ実行する
    $(document).on('click', '[data-id="changeCombineDifferentTemperatureType"]', function() {
      const $this = $(this);
      $this.addClass('disabled');
      const flag = $this.data('flag');
      const $form = $('[data-id="orderShippingForm"]');
      const csrfToken = $('meta[name=csrf-token]').attr('content');

      $form.find('input[data-id="fieldCombineDifferentTemperatureType"]').val(flag);
      $.ajax({
        url: $this.data('reload-url'),
        data: $form.serialize(),
        type: 'post',
        cache: false,
        headers: {
          'X-CSRF-TOKEN': csrfToken
        },
        success: function(response){
          $form.replaceWith(response.partial);
          $('[data-collapse-target]').trigger('initCollapse');
        }
      });
      return false;
    });
  }

  $('a[data-selector="samplePagePopup"]').on('click', function() {
    const selector = $(this).attr('href');
    $.magnificPopup.open(magnificPopupOptions({
      items: {
        src: $(selector),
        type: 'inline',
      }
    }));
    return false;
  });
});
