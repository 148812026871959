$(document).on('turbolinks:load', function(e) {
  $('.information-images.slick').slick({
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  });
});
