import { getCsrfToken } from '../lib/csrfToken';
import magnificPopupOptions from './magnific_popup_options';

$(document).on('turbolinks:load', function(e) {
  $('[data-id="variationListButton"]').on('click', e => {
    const selector = $(e.target).attr('href');
    $.magnificPopup.open(magnificPopupOptions({
      items: {
        src: $(selector),
        type: 'inline',
      }
    }));
    return false;
  });

  const moveUrl = url => {
    if(Turbolinks.supported){
      Turbolinks.visit(url);
    } else {
      window.location.href = url;
    }
  }

  const selector = 'select[name^="variation_type_option_ids"]';
  $('[data-id="variationSelectors"]').on('change', selector, () => {
    const data = $(selector).serialize();
    const url = $('[data-id="variationSelectors"]').data('reload-url')
    const csrfToken = getCsrfToken();
    console.log(decodeURI(data));
    console.log(url);
    $.ajax({
      url,
      data,
      type: 'get',
      cache: false,
      headers: {
        'X-CSRF-TOKEN': csrfToken
      },
      success: response => {
        console.log(JSON.stringify(response));
        if (response.url) {
          moveUrl(response.url)
        } else {
          // フォームを無効にする
          const $cartForm = $('[data-id="cartForm"]')
          $cartForm.addClass('disable')
          $cartForm.find('select, input, button').prop('disabled', true)
          $cartForm.find('a').addClass('disabled')
          $cartForm.find('[data-id="addCartButton"]').text('商品が存在しません')
          // 商品説明・画像をグレーアウトする
          const $itemImages = $('[data-id="itemImages"]')
          $itemImages.addClass('opacity-2')
          const $itemInformation = $('[data-id="itemInformation"]')
          $itemInformation.addClass('opacity-2')
        }
      }
    });
  });
});
